import { Component } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrEventLayout from '@/modules/common/views/layouts/level-two/event/event.layout.vue'

@Component({
  name: 'GtrEventIndexView'
})
export default class GtrEventIndexView extends GtrSuper {
  created () {
    this.$emit('update:layout', GtrEventLayout)
  }
}
